
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import { Account } from 'Models/accounts';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import SwitchControl from 'Control/Switch.vue';
import Button from 'Control/Button.vue';
import SubuserResponse from 'Entities/userLoginHistory/SubuserResponse';
import { assignAccount, getMembersList, removeAccount } from 'Store/v2/CorporateTeamMembers';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

type Data = {
    theme: typeof theme;
    selectedAccounts: Record<string, Record<string, boolean>>;
};

type Methods = {
    getAccountColor: (data: Account) => string;
    save: () => void;
    togglePolicy: (data: { accountId: string; policy: string; }) => void;
    getPolicyValue: (data: { accountId: string; policy: string; }) => boolean;
    goBack: () => void;
    initPolicies: () => void;
};

type Computed = {
    availablePolicies: string[];
    filteredPolicies: string[];
    hasAvailablePolicies: boolean;
    accounts: Account[];
    isThemeDark: boolean;
    firstManagedAccountIndex: number;
    editingUserEmail: string;
    editingUser: SubuserResponse | undefined;
};

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Button,
        SwitchControl,
        AccountColorMarker,
    },
    data() {
        return {
            theme,
            selectedAccounts: {},
        };
    },
    computed: {
        ...mapGetters({
            availablePolicies: 'Accounts/availablePolicies',
            hasAvailablePolicies: 'Accounts/hasAvailablePolicies',
            isThemeDark: 'isThemeDark',
        }),
        editingUser() {
            return this.$store.state.CorporateTeamMembers.membersList.find(({ email }) => email === this.editingUserEmail);
        },
        filteredPolicies() {
            return this.availablePolicies.filter((p) => p.toLowerCase() !== 'institution');
        },
        accounts() {
            const allAccounts = this.$store.state.Accounts.accounts;
            const ownedAccounts = allAccounts.filter(({ roleName }) => roleName === 'owner');
            const managedAccounts = allAccounts.filter(({ roleName }) => roleName !== 'owner');
            const ownedAccountsOrder = this.$store.state.Interface.config.ownedAccountsOrder ?? [];
            const managedAccountsOrder = this.$store.state.Interface.config.managedAccountsOrder ?? [];
            return [...(
                ownedAccounts.sort(
                    ({ id: a }, { id: b }) => {
                        const aOrder = ownedAccountsOrder.indexOf(a);
                        const bOrder = ownedAccountsOrder.indexOf(b);
                        return (aOrder === -1 ? Infinity : aOrder) - (bOrder === -1 ? Infinity : bOrder);
                    },
                )
            ), ...(
                managedAccounts.sort(
                    ({ id: a }, { id: b }) => {
                        const aOrder = managedAccountsOrder.indexOf(a);
                        const bOrder = managedAccountsOrder.indexOf(b);
                        return (aOrder === -1 ? Infinity : aOrder) - (bOrder === -1 ? Infinity : bOrder);
                    },
                )
            )].sort((
                { name: a },
                { name: b },
            ) => (
                // eslint-disable-next-line no-nested-ternary
                a === 'main'
                    ? -1
                    : b === 'main'
                        ? 1
                        : 0
            ));
        },
        firstManagedAccountIndex() {
            return this.accounts.findIndex(({ roleName }) => roleName !== 'owner');
        },
        editingUserEmail() {
            return this.$store.state.CorporateTeamMembers.editiongUserEmail;
        },
    },
    methods: {
        initPolicies() {
            if (!this.editingUser) {
                return;
            }

            const { accounts } = this.editingUser;
            if (!accounts) {
                return;
            }
            const temp = {};
            accounts.forEach((a) => {
                if (a.id) {
                    const tempPolicies = {};
                    this.filteredPolicies.forEach((p) => {
                        tempPolicies[p] = (a.policies ?? []).indexOf(p) !== -1;
                    });
                    temp[a.id] = tempPolicies;
                }
            });
            this.selectedAccounts = temp;
        },
        getAccountColor(account) {
            if (!account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        togglePolicy({ accountId, policy }) {
            const selectedAccounts = { ...this.selectedAccounts };
            if (selectedAccounts[accountId] === undefined) {
                const temp = {};
                this.filteredPolicies.forEach((p) => {
                    temp[p] = policy === p;
                });
                selectedAccounts[accountId] = temp;
                this.selectedAccounts = selectedAccounts;
                return;
            }
            selectedAccounts[accountId][policy] = !selectedAccounts[accountId][policy];
            const hasAnySelected = Object.values(selectedAccounts[accountId]).some((val) => val);
            if (!hasAnySelected) {
                delete selectedAccounts[accountId];
            }
            this.selectedAccounts = selectedAccounts;
        },
        getPolicyValue({ accountId, policy }) {
            const name = this.accounts.find(({ id }) => id === accountId)?.name;
            if (name === 'main') {
                return true;
            }
            return this.selectedAccounts[accountId] !== undefined ? this.selectedAccounts[accountId][policy] : false;
        },
        async save() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const userAccounts = this.editingUser?.accounts ?? [];
                const editingAccountsIds = Object.keys(this.selectedAccounts);
                const accountsToDelete = userAccounts
                    .filter(({ id, name }) => (
                        name !== 'main'
                        && (
                            editingAccountsIds.indexOf(id ?? '') === -1
                            || !Object.values(this.selectedAccounts[id ?? '']).some((p) => p)
                        )
                    ));

                await Promise.all(Array.from({ length: accountsToDelete.length }, (_, i) => this.$store.dispatch(removeAccount({
                    accountId: accountsToDelete[i] ? (accountsToDelete[i].id ?? '') : '',
                    subuserId: this.editingUser?.id ?? '',
                }))));

                await Promise.all(Array.from({ length: editingAccountsIds.length }, (_, i) => this.$store.dispatch(assignAccount({
                    accountId: editingAccountsIds[i],
                    policies: this.filteredPolicies.filter((p) => this.selectedAccounts[editingAccountsIds[i]][p]),
                    subuserId: this.editingUser?.id ?? '',
                }))));

                await this.$store.dispatch(getMembersList(undefined));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Accounts have been updated');
            } finally {
                await this.goBack();
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async goBack() {
            await this.$router.push('/profile/subusers-list').catch(() => { /* navigation error */ });
        },
    },
    async mounted() {
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            if (!this.editingUserEmail) {
                await this.goBack();
            }
            if (!this.hasAvailablePolicies) {
                await this.$store.dispatch('Accounts/downloadAvailablePolicies');
            }
            this.initPolicies();
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
});
